.navWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2.5rem;
  padding: 0 2.5rem;
}

.navBox {
  padding: 16px;
  backdrop-filter: blur(15px);
  border: 1px solid rgba(242, 242, 242, .1);
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 48px;
  color: #4f564d;
  font-weight: 400;
  display: flex;
  position: relative;
  font-family: Neue Montreal, sans-serif;
  font-weight: 300;
  border: 1px solid rgba(0, 0, 0, 0.099);
}

.navBox.mainNav {
  position: fixed;
  // top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 250px;
  z-index: 1000;
  



}


.mainHeader {
  padding: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}





.resume {
  width: 130px;
  height: 48px;
}

.navItem {
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

.navItem::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #916a4f;
  visibility: hidden;
  transition: all 0.3s ease;
}

.navItem:hover {
  color: #916a4f;
}

.navItem:hover::before {
  visibility: visible;
  width: 100%;
}

.burgerMenu {
  display: none;
}

@media screen and (max-width: 768px) {
  .mainNav {
      display: none;
  }

  .burgerMenu {
      display: block;
  }

  .navWrapper svg {
      width: 40px;
      height: 40px;
  }

  .resume {
      display: none;
  }

  .navBox {
      width: auto;
      height: auto;
      border: none;
  }
}
