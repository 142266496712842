.projectCard-grid {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.projectCard-grid.fadeIn {
    opacity: 1;
    transform: translateY(0);
}

.viewAllProjects-container {
    width: 100%;
    margin-top: 20px; /* Adjust as needed */
    padding-right: 20px; /* Adjust as needed */
}

.viewAllProjects {
    padding: 12px;
    backdrop-filter: blur(15px);
    border: 1px solid rgba(242, 242, 242, .1);
    border-radius: 24px;
    width: 170px;
    height: 48px;
    color: #4f564d;
    font-weight: 400;
    font-family: Neue Montreal, sans-serif;
    font-weight: 300;
    border: 1px solid rgba(0, 0, 0, 0.099);
 
    // transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.viewAllProjects.fadeIn {
    opacity: 1;
    transform: translateY(0);
}

.viewProjectItem {
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease;
}

.viewProjectItem::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #916a4f;
    visibility: hidden;
    transition: all 0.3s ease;
}

.viewProjectItem:hover {
    color: #916a4f;
}

.viewProjectItem:hover::before {
    visibility: visible;
    width: 100%;
}