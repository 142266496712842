

.description {
    flex: 2;
    padding-left: 20px;
    font-size: 16px;
    color: #333;
      margin: 0;
      letter-spacing: .4px;
      flex: 1;
      font-family: Neue Montreal, sans-serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 150%;
  }

  .project-header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    gap: 24px;

    .project-period {
        display: flex;
        flex-direction: row ;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        gap: 24px;
        font-size: 0.825rem;
        color: #4f564d;
    }    

    .project-title {
        font-size: 3.5rem;
        font-weight: 500;
        line-height: 1.2;
        color: #4f564d;
        text-align: center;
    }
  
  }

  .subHeader{
    font-family: Neue Montreal, sans-serif;
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
    margin: 0;
    min-width: 35%;
  }


  .projectContainer {
    font-family: Neue Montreal, sans-serif;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: black;
    width: 92%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    gap:34px;
}

.description {
    flex: 2;
    padding-left: 20px;
    font-size: 16px;
    color: #333;
    margin: 0;
    letter-spacing: .4px;
    font-family: Neue Montreal, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 150%;
}

.project-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    gap: 24px;
}

.project-period {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 10px;
}

.imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.imageContainer img {
    width: 100%;
    max-width: 800px;
    height: auto;
    border-radius: 24px;
}

.project-descriptionContainer {
    width: 100%;
    margin-top: 20px;
}

.project-title {
    text-align: center;
}

.projectExplanationGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.aboutPart {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.roleLink {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.role {
    text-align: center;
}

.link {
    display: flex;
    justify-content: center;
    gap: 10px;
}

/* Media Queries for responsiveness */
@media (min-width: 768px) {
    .projectExplanationGrid {
        grid-template-columns: 2fr 1fr;
    }
}

@media (min-width: 1024px) {
    .projectExplanationGrid {
        grid-template-columns: 2fr 1fr;
    }


}
    .link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

.buttonDesign{
    width: 70%;
    border: 1px solid #D0D2D5FF;
    font-size: 16px;
      letter-spacing: .4px;
      flex: 1;
      

      .MuiButton-label{
        font-family: Neue Montreal, sans-serif;
        font-weight: 300;
        color: #333;

    }
     
}

.roleHeader{
    font-size: 0.5rem;
    color: #4f564d; 
}


.techStack{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

  
  