@import '~typeface-lobster/index.css';


.work-experience-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-bottom: 20px;
  
    .company-info {
      display: flex;
      align-items: baseline;
      margin-bottom: 10px;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 1.5rem;
      padding-bottom: 2rem;
  
      h2 {
        font-family: 'Giest', sans-serif;
        font-size: 1.25rem;
        font-weight: 600;
        color: #333;
        margin: 0;
        min-width: 35%;
      }
  
      .period {
        font-size: 0.825rem;
        color: #4f564d;
      }


    }
  
    .roles-description {
      display: flex;
      justify-content: space-between;
  
      .roles {
        flex: 1;
        h3 {
          font-size: 0.875rem;
          font-weight: 500;
          color: #666;
          margin: 0 0 10px 0;
          font-family: Geist, Arial, sans-serif;

        }
      }
  
      .description {
        flex: 2;
        padding-left: 20px;
        font-size: 16px;
        color: #333;
  
        p {
          margin: 0;
          // color: rgba(242, 242, 242, .6);
          letter-spacing: .4px;
          flex: 1;
          font-family: Neue Montreal, sans-serif;
          font-size: 16px;
          font-weight: 300;
          line-height: 150%;
         }
      }
    }
  }
  