.pill {
    border: 0.5px solid #ab8e79;
    border-radius: 24px;
    font-size: 10px;
    margin-right: 12px;
    padding: 6px 12px 6px 12px;
    color: #fff;
    background-color: #ab8e79;
    min-width: 70px;
    text-align: center;
  }