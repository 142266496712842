// src/components/Loading.scss
.loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white;

  }


  
  // .loading-container {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  
  // .circle-mask {
  //   animation: grow-circle 1s ease-in-out forwards;
  // }
  
  // .circle-background {
  //   animation: grow-circle 1s ease-in-out forwards;
  // }
  
  // .animated-path {
  //   opacity: 0;
  //   stroke-dasharray: 100;
  //   stroke-dashoffset: 100;
  //   animation: draw-path 2s ease-in-out forwards;
  //   animation-delay: 1s; /* Delay path animation until the circle grows */
  // }
  
  // @keyframes grow-circle {
  //   0% {
  //     r: 0;
  //   }
  //   100% {
  //     r: 30;
  //   }
  // }
  
  // @keyframes draw-path {
  //   0% {
  //     opacity: 0;
  //     stroke-dashoffset: 100;
  //   }
  //   100% {
  //     opacity: 1;
  //     stroke-dashoffset: 0;
  //   }
  // }
  .svgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.svg-path {
    stroke-dasharray: 300;
    stroke-dashoffset: 200;
    animation: draw-path 2s ease-in-out forwards;
}

@keyframes draw-path {
    0% {
        stroke-dashoffset: 300;
    }
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fill-path {
    0% {
        fill: none;
    }
    100% {
        fill: black;
    }
}
  

  