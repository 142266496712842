.mainSectionContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 32px;
}

.workExperienceContainer{

    .workExp-card{
        margin: 1rem;
        padding: 2rem;

    }
    

    // .sectionTitle{
    //     margin: 1rem;
    //     padding: 2rem;
    // }

    .sectionTitle {
        margin: 1rem;
        padding: 2rem;
        opacity: 0;
        transform: perspective(1200px) translateX(0px) translateY(20px) scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) skewX(0deg) skewY(0deg) translateZ(0px);
        transition: opacity 1.2s ease-out, transform 1.2s ease-out;
    }
    
    .sectionTitle.in-view {
        opacity: 1;
        transform: perspective(1200px) translateX(0px)  scale(1) rotate(0deg) rotateX(0deg) rotateY(0deg) skewX(0deg) skewY(0deg) translateZ(0px);
    }
}


.projectsContainer {

    width: 100%;
    height: 100%;
}

.heroText-contact{
    -webkit-text-stroke-width: 1px;
    width: 100%;
    max-width: none;
    padding-bottom: 0;
    font-family: Neue Montreal, sans-serif;
    font-weight: 500;
    position: static;
    color:black;
    font-size: 2rem;
    text-align: center;
}

.contact-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 1rem;

    .icons-sections{
        margin: 1rem;
        color: #916a4f;
        padding-top: 1rem;

        svg{
            width: 2.5rem;
            height: 2.5rem;
            margin-right: 1rem;
           }
    }
}

.footer-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin: 1rem;
    color: #916a4f;
    font-family: Neue Montreal, sans-serif;
    font-weight: 300;
    font-size: 1rem;
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 0;

    p{
    margin-bottom: 0;

    }
}




