
  .app-Container{
    // grid-column-gap: .5rem;
    // grid-row-gap: .5rem;
    background-color: rgba(0, 0, 0, 0);
    border: 0 solid #353535;
    flex-direction: column;
    // grid-template-rows: auto auto;
    // grid-template-columns: 1fr;
    // grid-auto-columns: 1fr;
    width: 100%;
    max-width: 1400px;
    margin-top: 0;
    padding: 5rem;
    // display: grid;
    position: relative;
    top: 0;
    overflow: visible;
    height: 100%;
  }
  @media screen and (min-width: 1440px) {
    .app-Container {
      max-width: 1600px;
      padding: 5rem;
    }
    
  }

  @media screen and (max-width: 991px) {
    .app-Container {
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
    
  }
  
  @media screen and (max-width: 479px) {
    .app-Container {
      padding: 0 1rem;
    }
    
  }

