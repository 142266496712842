
// .heroContainer-wrapper {
//   display: flex;
//   flex-direction: column;
//   padding: 2rem;
//   align-items: center;
//   margin: 1rem;
//   min-height: calc(100vh - 10rem);
//   justify-content: space-between;
// }

// .hero-sect-1 {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   max-width: 1200px;
// }

// .heroText {
//   padding: 2rem 0;
//   max-width: 800px;
// }

// .secondaryHerotext {
//   padding-bottom: 1rem;
//   max-width: 800px;
//   letter-spacing: .4px;
//   font-family: Neue Montreal, sans-serif;
//   font-size: 16px;
//   font-weight: 300;
//   line-height: 150%;
//   color: #333;
// }

// // Responsive styles

// @media screen and (max-width: 768px) {
//   .hero-sect-1 {
//       flex-direction: column;
//       text-align: center;
//   }

//   .heroText, .secondaryHerotext {
//       padding: 1rem 0;
//       max-width: 100%;
//   }

//   img {
//       margin-top: 2rem;
//       height: auto;
//       width: 80%;
//   }
// }

// // Mouse animation
// .scroll-downs {
//   width: 34px;
//   height: 55px;
// }

// .mousey {
//   width: 3px;
//   padding: 10px 15px;
//   height: 35px;
//   border: 2px solid #fff;
//   border-radius: 25px;
//   opacity: 0.75;
//   box-sizing: content-box;
//   background-color: #916a4f;
// }

// .scroller {
//   width: 3px;
//   height: 12px;
//   border-radius: 25%;
//   background-color: black;
//   animation-name: scroll;
//   animation-duration: 2.2s;
//   animation-timing-function: cubic-bezier(.15,.41,.69,.94);
//   animation-iteration-count: infinite;
// }

// @keyframes scroll {
//   0% { opacity: 0; }
//   10% { transform: translateY(0); opacity: 1; }
//   100% { transform: translateY(15px); opacity: 0; }
// }



.heroContainer-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  margin: 1rem;
  min-height: calc(100vh - 10rem);
  justify-content: space-between;
  animation: fadeIn 1s ease-in-out;
}

.hero-sect-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.hero-text-container {
  animation: slideInLeft 1s ease-in-out;
}

.hero-image-container {
  animation: slideInRight 1s ease-in-out;
}

.heroText {
  padding: 2rem 0;
  max-width: 800px;
}

.hero-text-container {
  position: relative; /* Allows absolute positioning of children */
}

.animated-text {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-out forwards;
}

@keyframes fadeInUp {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.secondaryHerotext {
  padding-bottom: 1rem;
  max-width: 800px;
  letter-spacing: .4px;
  font-family: Neue Montreal, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 150%;
  color: #333;
}

// Mouse animation
.scroll-downs {
  width: 34px;
  height: 55px;
}

.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
  background-color: #916a4f;
}

.scroller {
  width: 3px;
  height: 12px;
  border-radius: 25%;
  background-color: black;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15, .41, .69, .94);
  animation-iteration-count: infinite;
}

@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideInLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
      transform: translateX(100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

// Responsive styles
@media screen and (max-width: 768px) {
  .hero-sect-1 {
      flex-direction: column;
      text-align: center;
  }

  .heroText, .secondaryHerotext {
      padding: 1rem 0;
      max-width: 100%;
  }

  img {
      margin-top: 2rem;
      height: auto;
      width: 80%;
  }
}
